// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import * as crypto from "crypto";

const generateSHA256 = content => {
  const buffer = Buffer.from(content, "hex");

  return crypto
    .createHash("sha256")
    .update(buffer)
    .digest("hex");
};

document.addEventListener("turbolinks:load", () => {
  const inputsSHA256 = document.querySelectorAll(
    "input[data-behavior='generate-sha256']"
  );

  for (const input of inputsSHA256) {
    input.addEventListener("change", event => {
      event.preventDefault();

      document.querySelector("#document-data").innerHTML = "";

      const reader = new FileReader();

      reader.readAsArrayBuffer(event.target.files[0]);

      reader.onloadend = () => {
        const sha256 = generateSHA256(reader.result);

        const target = document.querySelector(
          "input[data-behavior='target-sha256']"
        );

        target.value = sha256;
      };
    });
  }
});
